@import "./variables.scss";
// @import "./font-style.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,400&display=swap");

* {
  font-family: "Lato", sans-serif !important;

  &:focus {
    outline: none !important;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0 !important;
    font-weight: 800 !important;
  }

  p {
    margin-bottom: 0 !important;
  }

  .pb-0 {
    padding-bottom: 0 !important;
  }

  .mt-1 {
    margin-top: 10px !important;
  }

  .mt-2 {
    margin-top: 20px !important;
  }

  .ml-2 {
    margin-left: 20px;
  }

  .mr-2 {
    margin-right: 20px;
  }

  .ml-1 {
    margin-left: 15px;
  }

  .mt-3 {
    margin-top: 30px !important;
  }

  .mt-4 {
    margin-top: 40px !important;
  }

  .mt-5 {
    margin-top: 50px !important;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .w-full {
    width: 100%;
  }

  .BorderRadius {
    border-radius: $border-radius;
  }

  .error {
    font-size: 12px;
    color: map-get($i-colors, "text-error");
  }

  .no-internet-connection {
    color: color(forget-color);

    img {
      height: 80px;
    }
  }
}

:root {
  --scrollbar-track-color: transparent;
  --scrollbar-color: rgba(0, 0, 0, .2);

  --scrollbar-size: .4rem;
  --scrollbar-minlength: 1rem;
}

::-webkit-scrollbar {
  height: var(--scrollbar-size);
  width: var(--scrollbar-size);
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-color);
}

::-webkit-scrollbar-thumb:vertical {
  min-height: var(--scrollbar-minlength);
}

::-webkit-scrollbar-thumb:horizontal {
  min-width: var(--scrollbar-minlength);
}

body,
table {
  font-family: "Lato", sans-serif !important;
}

.no-data-found {
  p {
    font-weight: 600;
    color: map-get($i-colors, "text-main");
  }
}



.nav-bar-wrapper {
  width: 100%;

  .nav-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-name-email {
      .setting-option {
        width: 100%;
        display: flex;
        align-items: center;

        img {
          width: 35px;
        }

        .bottom-email-sec {
          width: calc(100% - 30px);
          position: relative;
          padding: 0 0 0 14px;

          &.open-dropdown {
            .user-profile-text {
              img {
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }

          .user-profile-text {
            width: 100%;
            font-family: "Lato", sans-serif !important;
            font-size: 1rem;
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 15px;

            span {
              white-space: nowrap;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            img {
              width: 15px;
              float: right;
              text-align: center;
            }
          }
        }

        .option-dropdown {
          text-align: right;
          cursor: pointer;
          width: auto;
          display: inline-block;
          float: right;
          margin-top: -2px;

          img {
            cursor: pointer;
          }

          &:hover {
            color: map-get($i-colors, "primary");
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.closed {
    margin-left: 68px;
  }
}

.logo-name {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;

  span {
    width: calc(100% - 40px);
    padding-left: 10px;
    color: map-get($i-colors, "dark-grey");
    font-weight: 800;
    word-break: break-word;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    line-height: normal;
  }
}

* {
  body {
    .MuiIconButton-root:hover {
      background-color: transparent;
    }

    #menu- {
      z-index: 9999 !important;

      ul {
        padding: 0;
      }
    }

    .cus-dialog {
      z-index: 9999 !important;

      h2 {
        text-transform: uppercase;
        font-size: 20px;
        text-align: center;
      }

      p {
        text-align: center;
        margin-top: 20px
      }

      >div>div {
        border-radius: 16px;
      }

      >div>div>div {
        border-radius: 0px;
      }

      .dialog-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 10px 0px 20px;

        button {
          min-height: 42px;
          padding: 8px 10px !important;
          min-width: 140px;
          font-weight: 600;
          margin: 5px;
        }
      }
    }

    .MuiAutocomplete-popper {
      z-index: 9999 !important;

      >div {
        -moz-box-shadow: $boxShadow !important;
        -webkit-box-shadow: $boxShadow !important;
        box-shadow: $boxShadow !important;
      }
    }

    .MuiToolbar-regular {
      min-height: 100%;
      padding: 30px;

      &.MuiTablePagination-toolbar {
        min-height: 100%;
        padding: 15px 30px;
        border-top: 1px solid map-get($i-colors, "color-grey");
      }
    }

    .cus-head-logo {
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border-bottom: 1px solid map-get($i-colors, "color-grey-2");
      padding: 10px 16px;
      z-index: 999999;
      text-align: center;
      justify-content: center;

      button {
        border-radius: 0;
      }
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: map-get($i-colors, "secondary");
        border-width: 2px;
      }
    }

    .MuiDrawer-paperAnchorDockedLeft {
      border-right: 0;
    }

    .nav-right {
      &.dashbord {
        padding: 0px;
      }

      h3 {
        margin: 0;
        font-size: 22px;
        font-weight: 900;
      }
    }

    span {
      &.badgec svg {
        height: 18px;
        width: 18px;
        margin-right: 4px;
      }
    }

    .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
      color: map-get($i-colors, "color-white") !important;
    }

    .MuiFormLabel-root.Mui-focused {
      color: map-get($i-colors, "secondary");
      font-weight: 900;
    }

    .table-loader {
      height: 350px;
      justify-content: center;
      display: flex;
      align-items: center;

      .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
        color: color(secondary) !important;
      }
    }

    /*** Login ***/

    .cus-public-header {
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: auto;
      min-height: 100vh;
      background: map-get($i-colors, "color-grey-4");

      .login-custom-width {
        background: map-get($i-colors, "color-white");

        img.logimg-icon {
          width: 100%;
          object-fit: cover;
          max-height: 650px;
          min-height: 650px;
          height: 100%;
        }

        .form-wrapper {
          border: 2px solid map-get($i-colors, "color-theme-3");
          border-right: 0;

          .form-link-sec {
            justify-content: flex-end;

            &.form-link-sign-in {
              justify-content: center;
            }

            a {
              text-decoration: underline !important;
              font-size: 14px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .full-w {
      &.custom-width {
        padding: 0 100px;
        width: 100%;

        .logo-wrapper {
          .logo {
            margin: 0 auto 20px;
            width: 130px;
            height: 100%;

            img {
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }

      .password-input {
        input {
          padding-right: 0;
        }

        button {
          padding: 0;
          margin: 0;
        }
      }

      .remember-me {
        label {
          margin: 0;
          text-align: left;

          >span {
            font-size: 14px;
            color: map-get($i-colors, "secondary");
            padding: 0 0;
            margin-right: 6px;
          }
        }
      }

      .sign-in-btn {
        width: 180px;
        font-size: 18px;
        margin: 24px 0;
        height: 41px;
      }

      .terms {
        letter-spacing: 0px;
        color: color(term-color);
        margin: 40px 0 0;
        font-size: 15px;
      }
    }

    .login-forgot-password {
      text-decoration: underline;
      cursor: pointer;
      display: inline-block;
    }

    /* End LogIn Css */
    .list-style {
      padding: 0;
      margin: 0;

      li {
        width: auto;
        list-style: none;
        margin: 0 0px 0 20px;

        &:first-child {
          margin: 0;
        }

        .select__control {
          min-width: 240px;
        }
      }
    }

    .MuiTableCell-head {
      font-weight: 800;
    }

    .logo-icon {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      object-fit: contain;
    }

    img.toggle-btn {
      height: 18px;
    }

    .button {
      &:hover {
        background-color: map-get($i-colors, "color-theme-22");
      }
    }

    .button-btn {
      border-radius: 30px;
      font-size: 14px;
      padding: 9px 16px;
      text-transform: capitalize;
      background: transparent;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      min-width: 200px;
      min-height: 42px;
      text-transform: uppercase;
      font-weight: bold;

      &.sign-in-btn {
        text-transform: none;
      }

      &.btn-custom-black {
        color: map-get($i-colors, "color-white");
        background: map-get($i-colors, "secondary");
        border-color: map-get($i-colors, "secondary");

        &:hover,
        &:active {
          background: map-get($i-colors, "primary");
          border: none;
          color: map-get($i-colors, "color-white");
        }

        &.MuiButton-outlined {
          border: 0;
        }
      }

      &.btn-custom-primry {
        color: map-get($i-colors, "color-white");
        background: map-get($i-colors, "primary");
        border-color: transparent;

        &:hover,
        &:active {
          background: map-get($i-colors, "secondary") !important;
          color: map-get($i-colors, "color-white");
          border: none;
        }

        &.MuiButton-outlined {
          border: 0;
        }

        &:disabled {
          background: map-get($i-colors, "secondary") !important;
          color: map-get($i-colors, "color-white");
          border: none;
          opacity: 0.4;
        }
      }

      &.btn-custom-border {
        background: map-get($i-colors, "color-white");
        color: map-get($i-colors, "primary");
        border: 2px solid map-get($i-colors, "primary");
        padding: 3px 16px;

        &:hover {
          border-color: map-get($i-colors, "primary") !important;
          color: map-get($i-colors, "primary");
        }

        &.add-partner-btn {
          padding: 4px 16px;
          min-width: 160px;
          min-height: 40px;
          font-weight: 400;
          border-radius: 6px;
        }

        &.btn-green {
          border-color: map-get($i-colors, "success-text");
          color: map-get($i-colors, "success-text");
        }

        &.get-zipcodes-btn {
          width: 60%;
          min-width: auto;
          max-width: 140px;
        }

        &.Get-ZIP-codes {
          min-width: 150px !important;
        }
      }

      &:focus {
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
      }

      &.button-download {
        color: map-get($i-colors, "color-white");
        padding: 0 20px;
        line-height: 48px;
        text-transform: capitalize;
        text-align: center;
        background: map-get($i-colors, "color-theme-4");
      }
    }

    .custom-modal {
      border-radius: 16px;
    }

    .heading-title-box {
      padding: 20px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    #long-menu {
      >div {
        border-radius: 8px;

        ul {
          padding: 0;
        }
      }

      ul {
        li {
          border-bottom: 1px solid map-get($i-colors, "color-theme-5");

          img {
            width: 12px;
            height: 12px;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    .back-sec {
      h3 {
        font-size: 20px;
        white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }

      h2 {
        margin: 0;
        display: flex;
        align-items: center;
        line-height: normal;

        svg {
          font-size: 24px;
          height: auto;
          position: relative;
          top: -1px;
        }
      }
    }

    .select-emp-sec {
      position: relative;

      .button-btn {
        &.btn-custom-primry {
          color: map-get($i-colors, "color-black");
          min-width: auto;
          position: absolute;
          bottom: -18px;
          right: 10px;
          min-height: auto;
          padding: 0;
          font-size: 12px;
          text-transform: capitalize;
          background-color: transparent !important;
          text-decoration: underline;
          border-radius: 0;
          line-height: normal;
        }
      }
    }
  }
}

main {
  // padding: 6px 0 0 !important;
  // overflow: hidden;

  .MuiInput-underline:after {
    border-bottom: 2px solid map-get($i-colors, "secondary");
  }

  .MuiFilledInput-underline:after {
    border-bottom: 0;
  }

  .MuiInputLabel-filled {
    transform: translate(12px, 14px) scale(1);
    -moz-transform: translate(12px, 14px) scale(1);
    -webkit-transform: translate(12px, 14px) scale(1);
    font-size: 16px;

    &.MuiInputLabel-shrink {
      transform: translate(12px, 0) scale(0.75);
      -moz-transform: translate(12px, 0) scale(0.75);
      -webkit-transform: translate(12px, 0) scale(0.75);
    }
  }

  .MuiFilledInput-input {
    padding: 16px 12px 10px;
  }

  .card-custom {
    height: 100%;
    border-radius: 12px;
    -moz-box-shadow: $boxShadow;
    -webkit-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
  }

  .title-heading {
    font-size: 20px;
    margin: 0;
  }

  .table-design {
    display: grid;
    background: map-get($i-colors, "color-white");
    -moz-box-shadow: $boxShadow !important;
    -webkit-box-shadow: $boxShadow !important;
    box-shadow: $boxShadow !important;
    border-radius: 5px;
    padding: 12px 0 0;
    margin-top: 20px;
    overflow: visible;

    tr.MuiTableRow-root {
      &.MuiTableRow-hover:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    .tab-con-form {
      text-align: center;
      padding: 30px 100px;
      min-height: 400px;

      .MuiBox-root {
        position: relative;

        .meal-setting-currency,
        .meal-setting-percentage {
          position: absolute;
          right: 2px;
          top: 2px;
          background: map-get($i-colors, "grey-shade");
          height: 52px;
          width: 50px;
          display: flex;
          align-content: center;
          justify-content: center;
          line-height: 50px;
          text-align: center;
          font-size: 20px;
          border-radius: 4px;
          color: map-get($i-colors, "dark-grey");
        }
      }

      h5 {
        padding-bottom: 30px;
        width: 100%;
        display: inline-block;
      }

      .meal-set-heading {
        width: 100%;
        display: inline-block;
        text-align: left;
        margin-bottom: 6px;

        h4 {
          color: map-get($i-colors, "secondary");
          font-size: 18px;
          width: 100%;
          display: inline-block;

          .meal-type-dot {
            margin-left: 0;
          }
        }
      }

      .error {
        &.text-left {
          width: 100%;
          display: inline-block;
        }
      }

      .cor-msg {
        width: 100%;
        display: inline-block;
        text-align: left;
        color: map-get($i-colors, "color-theme-30");
        margin-top: 2px;
      }

      .set-tabs-btn {
        width: 100%;
        display: inline-block;
        margin: 20px 0;

        button {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 16px;
          color: map-get($i-colors, "dark-grey");
        }
      }

      .update-pass-tab {
        width: 100%;
        display: inline-block;
        margin: auto;
        max-width: 600px;
      }

      .upload-logo-sec {
        width: 100%;
        // display: flex;
        // align-items: self-start;
        // // flex-direction: column;

        .upload-logo-btn {
          height: 150px;
          background: #f2f2f2;
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 200px;
          margin-right: 10px;
          flex-direction: column;
          justify-content: center;

          label {
            box-shadow: none;
            width: 100%;
            min-width: auto;
            padding: 0;
            font-size: 14px;
            background: transparent;
            text-transform: capitalize;
            height: 100%;

            span {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .uploaded-img {
          border-radius: 4px;
          width: 150px;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }
      }
    }
  }



  .billing-table-design {
    display: grid;
    background: map-get($i-colors, "color-white");
    -moz-box-shadow: $boxShadow !important;
    -webkit-box-shadow: $boxShadow !important;
    box-shadow: $boxShadow !important;
    border-radius: 5px;
    margin-top: 10px;
    overflow: visible;

    tr.MuiTableRow-root {
      &.MuiTableRow-hover:last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    .tab-con-form {
      text-align: center;
      padding: 30px 100px;
      min-height: 400px;

      .MuiBox-root {
        position: relative;

        .meal-setting-currency,
        .meal-setting-percentage {
          position: absolute;
          right: 2px;
          top: 2px;
          background: map-get($i-colors, "grey-shade");
          height: 52px;
          width: 50px;
          display: flex;
          align-content: center;
          justify-content: center;
          line-height: 50px;
          text-align: center;
          font-size: 20px;
          border-radius: 4px;
          color: map-get($i-colors, "dark-grey");
        }
      }

      h5 {
        padding-bottom: 30px;
        width: 100%;
        display: inline-block;
      }

      .meal-set-heading {
        width: 100%;
        display: inline-block;
        text-align: left;
        margin-bottom: 6px;

        h4 {
          color: map-get($i-colors, "secondary");
          font-size: 18px;
          width: 100%;
          display: inline-block;

          .meal-type-dot {
            margin-left: 0;
          }
        }
      }

      .error {
        &.text-left {
          width: 100%;
          display: inline-block;
        }
      }

      .cor-msg {
        width: 100%;
        display: inline-block;
        text-align: left;
        color: map-get($i-colors, "color-theme-30");
        margin-top: 2px;
      }

      .set-tabs-btn {
        width: 100%;
        display: inline-block;
        margin: 20px 0;

        button {
          text-transform: uppercase;
          font-weight: 900;
          font-size: 16px;
          color: map-get($i-colors, "dark-grey");
        }
      }

      .update-pass-tab {
        width: 100%;
        display: inline-block;
        margin: auto;
        max-width: 600px;
      }

      .upload-logo-sec {
        width: 100%;
        // display: flex;
        // align-items: self-start;
        // // flex-direction: column;

        .upload-logo-btn {
          height: 150px;
          background: #f2f2f2;
          display: flex;
          align-items: center;
          border-radius: 4px;
          width: 200px;
          margin-right: 10px;
          flex-direction: column;
          justify-content: center;

          label {
            box-shadow: none;
            width: 100%;
            min-width: auto;
            padding: 0;
            font-size: 14px;
            background: transparent;
            text-transform: capitalize;
            height: 100%;

            span {
              display: flex;
              flex-direction: column;
            }
          }
        }

        .uploaded-img {
          border-radius: 4px;
          width: 150px;

          img {
            width: 100%;
            border-radius: 4px;
          }
        }
      }
    }
  }



  .more-btn {
    color: map-get($i-colors, "color-black");
    opacity: 1;
  }

  .table-program {
    tbody {
      td {
        color: map-get($i-colors, "secondary");

        .menu-field-type {
          width: 100%;
          display: flex;
          align-items: center;

          p {
            margin-left: 15px;
            display: flex;
            align-items: center;

            span {
              margin-left: 5px;
              text-transform: capitalize;
            }
          }
        }

        a {
          color: map-get($i-colors, "secondary");
        }

        &.green {
          color: color(active-color) !important;
        }

        svg {
          // color: map-get($i-colors, "secondary");
          height: 20px !important;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    th {
      line-height: normal;
      background-color: map-get($i-colors, "color-white");
      color: map-get($i-colors, "text-main");

      &:last-child {
        span {
          display: none;
        }
      }
    }

    .table-title {
      white-space: inherit;
    }

    td {
      word-break: break-word;
      white-space: nowrap;

      button {
        padding: 0 !important;
        line-height: 0 !important;

        &:hover {
          background-color: transparent;
        }
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background: map-get($i-colors, "color-white");
        border-left: 1px solid map-get($i-colors, "color-grey");
      }

      .show-more-span {
        color: map-get($i-colors, "text-error");
        font-family: "Lato", sans-serif !important;
      }
    }

    th,
    td {
      padding: 17px 11px;
      min-width: 140px;

      &:first-child,
      &:first-child {
        padding-left: 25px;
      }

      .more-button {
        background: map-get($i-colors, "primary");
        padding: 4px 6px 2px 10px;
        border-radius: 30px;
        color: map-get($i-colors, "color-white");
        font-size: 12px;
        text-align: center;
        display: inline-block;

        svg {
          color: map-get($i-colors, "color-white") !important;
          width: 20px;
        }
      }

      .MuiChip-label {
        text-transform: capitalize;
      }
    }

    [type="button"]:not(:disabled) {
      cursor: pointer;
    }

    .partner-badge {
      border: 1px solid map-get($i-colors, "color-grey-1");
      padding: 3px 10px;
      border-radius: 30px;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;
      margin-right: 4px;
    }
  }


  .employee-table-program {
    tbody {
      td {
        color: map-get($i-colors, "secondary");

        .menu-field-type {
          width: 100%;
          display: flex;
          align-items: center;

          p {
            margin-left: 15px;
            display: flex;
            align-items: center;

            span {
              margin-left: 5px;
              text-transform: capitalize;
            }
          }
        }

        a {
          color: map-get($i-colors, "secondary");
        }

        &.green {
          color: color(active-color) !important;
        }

        svg {
          // color: map-get($i-colors, "secondary");
          height: 20px !important;
        }
      }

      tr {
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }

    th {
      line-height: normal;
      background-color: map-get($i-colors, "color-white");
      color: map-get($i-colors, "text-main");

      &:last-child {
        span {
          display: none;
        }
      }
    }

    .table-title {
      white-space: inherit;
    }

    td {
      word-break: break-word;
      white-space: nowrap;

      button {
        padding: 0 !important;
        line-height: 0 !important;

        &:hover {
          background-color: transparent;
        }
      }

      &.more {
        position: sticky;
        width: auto;
        right: 0;
        background: map-get($i-colors, "color-white");
        border-left: 1px solid map-get($i-colors, "color-grey");
      }

      .show-more-span {
        color: map-get($i-colors, "text-error");
        font-family: "Lato", sans-serif !important;
      }
    }

    th,
    td {
      padding: 17px 11px;
      // min-width: 140px;  FOR EMPLOYEE LIST SCROLL BAR

      &:first-child,
      &:first-child {
        padding-left: 25px;
      }

      .more-button {
        background: map-get($i-colors, "primary");
        padding: 4px 6px 2px 10px;
        border-radius: 30px;
        color: map-get($i-colors, "color-white");
        font-size: 12px;
        text-align: center;
        display: inline-block;

        svg {
          color: map-get($i-colors, "color-white") !important;
          width: 20px;
        }
      }

      .MuiChip-label {
        text-transform: capitalize;
      }
    }

    [type="button"]:not(:disabled) {
      cursor: pointer;
    }

    .partner-badge {
      border: 1px solid map-get($i-colors, "color-grey-1");
      padding: 3px 10px;
      border-radius: 30px;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      text-transform: capitalize;
      margin-right: 4px;
    }
  }

  .table-design {
    &.manage-users-pg {
      padding: 30px;

      .cus-back-head {
        &.just-spc {
          justify-content: space-between;
          margin: 0;
        }
      }

      .cus-back-head {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        h3 {
          width: auto;
          display: flex;
          align-items: center;
          font-size: 16px;
          cursor: pointer;

          span {
            margin-left: 6px;
          }
        }
      }

      .adduser-form {
        width: 100%;
        display: inline-block;
        overflow: hidden;

        &.edit-user-form {
          .edit-form {
            label {
              transform: translate(14px, -6px) scale(0.75) !important;
              -webkit-transform: translate(14px, -6px) scale(0.75) !important;
              -moz-transform: translate(14px, -6px) scale(0.75) !important;
            }

            legend {
              max-width: 1000px;
              transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
            }
          }
        }

        .cus-permissions-main {
          width: 100%;
          display: inline-block;
          padding: 20px 0;
          margin-top: 20px;

          .cus-permissions-inner {
            width: 100%;
            display: inline-block;
            background: map-get($i-colors, "color-theme-31");
            border-radius: 6px;
            padding: 20px;
            margin: 20px 0;

            h3 {
              font-size: 24px;
            }

            .per-listing {
              width: 100%;
              display: inline-block;
              margin-bottom: 25px;

              span {
                color: map-get($i-colors, "secondary");
                font-size: 14px;

                svg {
                  width: 22px;
                  height: 22px;
                }
              }

              .per-head {
                width: 100%;
                display: inline-block;

                span {
                  font-weight: bold;
                }

                .MuiCheckbox-root {
                  color: map-get($i-colors, "secondary");
                }

                .MuiCheckbox-colorPrimary {
                  &.Mui-checked {
                    color: map-get($i-colors, "secondary");
                  }
                }
              }

              ul {
                width: 100%;
                display: inline-block;
                padding: 0 0 0 35px;
                margin: 0;
                list-style: none;

                li {
                  width: 100%;
                  display: inline-block;

                  span {
                    &.MuiButtonBase-root {
                      padding: 4px;
                    }

                    &.MuiFormControlLabel-label {
                      opacity: 0.7;
                    }
                  }

                  .Mui-checked {
                    +span {
                      &.MuiFormControlLabel-label {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
          }

          .btn-save {
            width: 100%;
            display: inline-block;
            text-align: center;
            margin-top: 20px;

            button {
              margin: 5px 10px;
              min-width: 150px;
              box-shadow: none;
              font-weight: 600;
              padding: 8px 16px;
            }
          }
        }
      }
    }

    >div {
      overflow: auto;
    }
  }
}

.cus-popup {
  z-index: 9999 !important;

  .update-invoice-modal {
    width: 100%;
    display: inline-block;

    h6 {
      background-color: map-get($i-colors, "yellow-color");
      ;
      padding: 16px 24px;
      color: map-get($i-colors, "text-main");
      ;
      font-size: 1.25rem;
    }

    .update-invoice-inner {
      padding: 16px 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      width: 100%;
      display: inline-block;
    }
  }
}

.user-profile-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    cursor: pointer;
  }

  .option-dropdown {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-right: 15px;

    h5 {
      line-height: 0.8;
      font-size: 16px;
      font-weight: 700 !important;
      color: map-get($i-colors, "secondary");
    }

    span {
      font-size: 12px;
    }
  }

  img {
    width: 22px;
  }
}

/* Sidebar */
.custom-menu {
  >div {
    justify-content: space-between;
  }

  .sidebar-menus {
    height: calc(100% - 70px);
    overflow: hidden;
    overflow-y: auto;
    padding: 0 0;
    border-right: 1px solid map-get($i-colors, "color-grey-2");

    h6 {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 600 !important;
    }

    .active {
      h6 {
        color: map-get($i-colors, "primary");
      }
    }

    .sidepanel-item {
      margin: 0 0;
      padding: 20px 15px;
      border-radius: 0px;
      opacity: 0.7;
      border-left: 4px solid map-get($i-colors, "color-white");
      border-bottom: 1px solid map-get($i-colors, "border-main");

      &.active {
        opacity: 1;
        border-left: 4px solid map-get($i-colors, "primary");
      }

      .sidebar-menu-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 38px;

        svg,
        img {
          width: 24px;
          height: 24px;
        }
      }

      .sidebar-menu-text {
        margin: 0 0 0 15px;
        color: map-get($i-colors, "secondary");
      }

      &:hover {
        opacity: 1;
        border-left: 4px solid map-get($i-colors, "primary");
      }
    }

    >.sidepanel-item:first-child {
      .sidebar-menu-icon {
        img {
          width: 18px;
        }
      }
    }
  }
}

.top-header {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid map-get($i-colors, "color-grey-3");

  .header-inner {
    height: 69px;
    padding: 10px 60px;
  }
}

// For Material Date Picker
.material-datepicker {
  background: map-get($i-colors, "color-grey-4");
  border-radius: 9px;

  .date-picker-material-ui {
    min-width: 180px;
    margin-top: 0px;
    margin: 0;

    > :before,
    > :after {
      width: 0;
    }
  }
}

.MuiDialog-root {
  z-index: 9999 !important;
}

body {
  legend {
    float: none;
  }
}

.sidebar-menu-small-icon {
  img {
    width: 22px;
  }
}

.DateRangePicker {
  .CalendarDay__selected {
    background-color: map-get($i-colors, "primary") !important;

    &:active {
      background-color: map-get($i-colors, "primary");
    }

    &:hover {
      background-color: map-get($i-colors, "primary");
    }
  }

  .CalendarDay__selected_span {
    background-color: map-get($i-colors, "color-grey-5");
  }
}

/* Date Picker Css Start */
.align-center {
  align-items: center;
}

.DateInput {
  .DateInput_input {
    font-weight: 500;
    font-size: 14px;
    color: map-get($i-colors, "secondary");
    padding: 0 4px;
    line-height: 28px;
    border: 0;
  }
}

/* Date Picker Css Ends */

//Graph//

.apexcharts-yaxis-label {
  color: map-get($i-colors, "text-error") !important;
}

.bottomRight-notification-notice-content {
  div {
    >div {
      max-width: 100%;

      >div {
        position: relative;
        z-index: 999999;
      }
    }
  }
}

.IdleTimer-modal {
  p {
    font-weight: bold;
    font-size: 16px;

    span {
      color: map-get($i-colors, "primary");
    }
  }
}

.logout-icon {
  height: 24px;
  width: 24px;
}

.show-hide {
  cursor: pointer;
}

body {
  .MuiSelect-select {
    &:focus {
      border-radius: 0;
      background-color: transparent;
    }
  }
}

.MuiLinearProgress-colorPrimary {
  background-color: map-get($i-colors, "color-theme-1") !important;

  .MuiLinearProgress-barColorPrimary {
    background-color: map-get($i-colors, "primary");
  }
}

.disabled {
  opacity: 0.3;
  cursor: default;
  pointer-events: none;
}

.green {
  color: color(active-color) !important;
  font-weight: 600 !important;
}

.green_span {
  color: map-get($i-colors, "color-green");
  font-weight: 600 !important;
}

.red {
  color: map-get($i-colors, "primary");
  font-weight: 600 !important;
}

.show-pointer {
  cursor: pointer;
}

.text-center {
  text-align: cetner !important;
}

//For Public Site

.text-left {
  text-align: left !important;
}

* {
  body {
    .DateRangePicker_picker {
      z-index: 99;
    }
  }
}

.yellow {
  color: map-get($i-colors, "color-grey-6") !important;
  font-weight: 600 !important;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

main {
  .table-program {
    .cursor_default {
      tbody td {
        cursor: auto;

        .cursor_pointer {
          cursor: pointer;
        }
      }
    }
  }
}

.cursor_pointer {
  cursor: pointer;
}

.font-weight600 {
  font-weight: 600 !important;
}

.text_current_bal {
  color: map-get($i-colors, "color-grey-6") !important;
}

.hover_underLine:hover {
  text-decoration: underline;
}

.input-group {
  position: relative;

  .help-block {
    left: 0;
    position: absolute;
    bottom: -20px;
  }
}

.logout_hover {
  &:hover {
    color: map-get($i-colors, "color-grey-6") !important;
  }
}

.w-100 {
  width: 100%;
}

body {
  background-color: map-get($i-colors, "bg-light") !important;

  .MuiAutocomplete-popper {
    ul {
      padding: 0 !important;
    }
  }
}

//manage location

.back-cursor {
  cursor: pointer;
}

.delivery-partner {
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    &.custon-list {
      font-size: 16px;
      width: 100%;
      border: 1px solid map-get($i-colors, "color-theme-2");
      display: block;
      padding: 16px 12px;
      border-radius: 4px;
      position: relative;
      text-transform: capitalize;
      margin-bottom: 20px;

      span.menu-icon {
        position: absolute;
        right: 16px;
        top: 15px;
      }
    }
  }
}

.dashboard {
  width: 100%;
}

.dashboard_images {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 15px;

  img {
    width: 100%;
    max-height: 48px;
  }
}

div#chart,
.apexcharts-canvas {
  width: 100% !important;
}

.apexcharts-canvas svg {
  width: 100%;
}

.boxshadow-custom {
  background: map-get($i-colors, "color-white");
  box-shadow: $boxShadow-cutom !important;
  -moz-box-shadow: $boxShadow-cutom !important;
  -webkit-box-shadow: $boxShadow-cutom !important;
  border-radius: 4px;
}

.box-shadow {
  -moz-box-shadow: $boxShadow !important;
  -webkit-box-shadow: $boxShadow !important;
  box-shadow: $boxShadow !important;
}

.bb-filter-border {
  border: 1px solid map-get($i-colors, "color-theme-6");
  border-radius: 4px;
}

.menu-icon {
  svg {
    color: map-get($i-colors, "color-theme-7");
  }
}

.heading-chart {
  border-bottom: 1px solid map-get($i-colors, "color-theme-8");

  .view-all-button {
    background-color: map-get($i-colors, "primary");
    color: map-get($i-colors, "color-white");
    padding: 10px 30px;
    border-radius: 40px;
    font-size: 14px;
    font-weight: 600;

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: map-get($i-colors, "primary");
    }
  }
}

.dashboard-container {
  .revenue-card-box {
    .revenue-card-container {
      padding: 20px;

      .title {
        font-weight: 600;
        margin-bottom: 8px;
        color: map-get($i-colors, "secondary");
        font-size: 14px;
      }

      .card-dashboard {
        font-size: 20px;
        font-weight: 500 !important;
        margin-top: 8px;
      }

      .amount {
        font-weight: 600;
      }

      .green {
        color: map-get($i-colors, "success-text");
        font-weight: 500 !important;
      }

      .red {
        color: map-get($i-colors, "primary");
        font-weight: 500 !important;
      }
    }
  }

  .chart-box-container {
    padding: 0px 20px;
    min-height: 365px;

    .chart-box {
      height: 385px;
    }

    &.deliveries-avg-order-chart {
      padding: 0;
    }
  }

  .dashboard-filters {
    .autoselect-date-filters {
      .date-range-picker {
        .CalendarDay__selected_span {
          background: map-get($i-colors, "color-theme-21");
          color: map-get($i-colors, "color-white");
          border: 1px solid map-get($i-colors, "color-white");
        }

        .CalendarDay__selected {
          background: map-get($i-colors, "color-theme-21");
          color: map-get($i-colors, "color-white");
          border: 1px solid map-get($i-colors, "color-white");
        }

        .CalendarDay__selected:hover {
          background: map-get($i-colors, "primary");
          color: map-get($i-colors, "color-white");
          border: 1px solid map-get($i-colors, "color-white");
        }

        .CalendarDay__hovered_span:hover,
        .CalendarDay__hovered_span {
          background: map-get($i-colors, "color-theme-21");
          color: map-get($i-colors, "color-white");
          border: 1px solid map-get($i-colors, "color-white");
        }

        .start-end-date-divider {
          font-size: 25px;
          color: map-get($i-colors, "color-theme-23");
        }

        .DateRangePickerInput__withBorder {
          border-radius: 5px;
          border: 1px solid map-get($i-colors, "color-theme-2");
          height: 48px;
          min-width: 330px;
          display: flex;
          align-items: center;
        }

        .date-picker-icon {
          color: map-get($i-colors, "color-theme-9");
        }
      }
    }

    .type-container {
      position: relative;

      .location-input-field {
        display: flex;
        align-items: center;

        .display-selection {
          font-size: 18px;
          font-weight: 600;
        }

        img {
          margin-right: 10px;
          width: 30px;
        }
      }

      .dropdown-container {
        position: absolute;
        max-height: 280px;
        width: 100%;
        padding: 10px;
        overflow-y: auto;
        z-index: 10;

        .location-item {
          padding: 10px;
          border-radius: 5px;
          cursor: pointer;

          p {
            height: 100%;
            font-size: 20px;
          }
        }

        .active-item {
          background-color: map-get($i-colors, "color-theme-24");
        }

        .non-active-item {
          &:hover {
            background-color: map-get($i-colors, "color-theme-25");
          }
        }
      }
    }
  }

  .divided-chart-list {
    max-height: 350px;
    overflow: auto;

    table {
      thead {
        tr {
          th {
            p {
              color: map-get($i-colors, "color-grey");
              font-size: 16px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 16px 0px 16px 16px;

            p {
              display: flex;
              align-items: center;
              position: relative;

              span {
                left: -16px;
                top: 50%;
                position: absolute;
                margin-right: 10px;
                border-radius: 10px;
                width: 8px;
                height: 8px;
              }
            }

            &:nth-child(1) {
              padding: 16px 0px 16px 16px;

              p {
                width: 150px;
              }
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid map-get($i-colors, "color-white");
            }
          }
        }
      }
    }
  }
}

.custom-search-field {
  display: flex;
  background-color: map-get($i-colors, "color-white");
  padding: 10px;
  width: 100%;
  align-items: center;
  height: 48px;
  margin-right: 20px;
  max-width: 260px;

  .search-icon {
    margin-left: 4px;
    margin-right: 8px;

    svg {
      color: map-get($i-colors, "secondary");
    }
  }

  input {
    width: 100%;
    font-size: 16px;
    background: transparent;
    border: none;
    color: map-get($i-colors, "secondary");
    outline: none;
  }

  svg {
    color: map-get($i-colors, "secondary");
  }
}

.custom-table-pagination {
  border-top: 1px solid map-get($i-colors, "border-main");
  padding: 20px;

  .pagination-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .showing-items {
      display: flex;
      align-items: center;
      background: map-get($i-colors, "color-theme-5");
      padding: 9px 20px;
      border-radius: 30px;

      P {
        font-family: "Lato", sans-serif !important;

        span {
          color: map-get($i-colors, "primary");
          font-weight: 600;
        }
      }

      svg {
        color: map-get($i-colors, "primary");
        font-size: 18px;
        margin-left: 15px;
        cursor: pointer;
      }

      @keyframes refresh-svg {
        from {
          -webkit-transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
        }
      }

      .refresh-svg {
        animation: refresh-svg;
        -webkit-animation: refresh-svg 2s linear infinite;
      }
    }

    .MuiPagination-ul {
      .MuiPaginationItem-page {
        &.Mui-selected {
          border-radius: 6px;
        }
      }

      li {

        &:first-child button,
        &:nth-child(2) button,
        &:last-child button,
        &:nth-child(10) button {
          margin: 0;
          padding: 0 !important;
        }

        &.MuiPaginationItem-root {
          border-radius: 0;
        }

        .MuiPaginationItem-icon {
          // color: map-get($i-colors, "primary");
          color: "#0000";
        }
      }
    }
  }
}

.brands-title {
  text-transform: capitalize;
}

.edit-pop-up li {
  cursor: default;
}

.common-add-component {
  .header {
    padding: 20px;
    border-bottom: 1px solid map-get($i-colors, "border-main");

    .img-add-inner {
      height: 18px;
      margin-right: 10px;
    }
  }

  .content {
    min-height: 400px;
  }

  .footer {
    border-top: 1px solid map-get($i-colors, "border-main");
    padding: 20px;

    .buttons-div {
      display: flex;
      float: right;

      button {
        min-width: 160px;
        margin: 0 10px;
        padding: 10px 16px;
      }
    }
  }
}

.add-location-form-container {
  padding: 20px 40px;

  .add-location-form {
    label {
      color: map-get($i-colors, "secondary");
      font-weight: 600;
      font-size: 14px;
    }
  }

  .address-heading {
    margin: 40px 0 20px;
    padding: 0 10px;
  }

  .p-top-30 {
    padding-top: 30px;
  }

  .p-top-20 {
    padding-top: 20px;
  }

  .add-partner-item {
    padding: 0px 10px;
    margin-bottom: 30px !important;

    &.login-addpartner {
      .button-btn {
        &.btn-custom-border {
          margin-top: 24px;
          height: 48px;
          min-width: 120px;
        }
      }
    }
  }
}

.MuiTouchRipple-root {
  span {
    background-color: transparent !important;
  }
}

.MuiTouchRipple-rippleVisible {
  opacity: 0 !important;
}

.transaction-page {
  .list-style {
    width: 100%;
    justify-content: flex-end;
    align-items: center;
  }
}

.textarea-input-field {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  font-family: "Lato", sans-serif !important;
  font-size: 1rem;
  border-color: map-get($i-colors, "color-theme-26");
  overflow: auto;
  resize: none;

  &::placeholder {
    color: map-get($i-colors, "color-theme-10");
  }

  &:hover {
    border-color: map-get($i-colors, "color-black");
  }

  &:focus {
    border-color: map-get($i-colors, "color-black");
    border-width: 2px;
  }

  &.error-active {
    border-color: map-get($i-colors, "text-error");
  }
}

.tabs-custom-dashboard {
  background: map-get($i-colors, "color-white");

  button {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 14px;
    padding: 9px 0;
  }
}

.custom-tab-selected {
  color: map-get($i-colors, "primary") !important;
}

.color-theme {
  color: map-get($i-colors, "primary");
}

.no-pt {
  &:hover {
    // cursor: default !important;
    color: map-get($i-colors, "primary");
  }
}

.show-inventory {
  color: map-get($i-colors, "primary");
  text-decoration: underline;
  cursor: pointer;
}

.active-inventory {
  color: map-get($i-colors, "primary") !important;
  text-decoration: none !important;
}

.font-bold {
  font-weight: 700;
}

/* Page Heading */

.location-container {
  padding: 25px;

  .showing-record-name {
    margin-bottom: -15px;

    h5 {
      font-size: 18px;

      span {
        color: map-get($i-colors, "primary");
        text-transform: capitalize;
      }
    }
  }

  .location-head {
    .heading {
      h2 {
        display: flex;
        align-items: center;
        font-size: 24px;
        font-weight: 600;

        svg {
          color: map-get($i-colors, "primary");
          font-size: 32px;
          margin-right: 10px;
        }

        img {
          width: 24px;
          margin-right: 10px;
          height: 24px;
        }
      }
    }

    .content-container {
      .show-more-span {
        color: map-get($i-colors, "primary");
      }
    }

    .search-add {
      display: flex;
    }
  }

  .add-loader {
    svg {
      color: map-get($i-colors, "color-white") !important;
    }
  }

  .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: map-get($i-colors, "primary") !important;
  }
}

/*Download btn*/
.download-btn {
  padding: 10px 16px !important;
  background-color: #f9c546 !important;
}

/*back btn*/
.back-btn {
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: map-get($i-colors, "primary");
  font-weight: 600;
  cursor: pointer;

  .back-icon {
    width: 24px;
    background: map-get($i-colors, "color-white");
    text-align: center;
    color: map-get($i-colors, "primary");
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($i-colors, "color-theme-11");
    margin-right: 10px;
  }

  svg {
    width: 14px;
    color: map-get($i-colors, "secondary");
    margin-left: 4px;
  }
}

.custom-span-element {
  font-size: 14px;
  margin-left: 2px;
}

.select__menu {
  z-index: 99999 !important;
}

.select__menu-list {
  z-index: 99999 !important;
  padding: 0 !important;
}

.heading {
  display: flex;
}

.transaction-page {
  justify-content: flex-end;
}

.MuiFormGroup-root {
  position: relative;

  .error-messages {
    font-size: 12px;
    color: map-get($i-colors, "text-error") !important;
    position: absolute;
    bottom: -18px;
    left: 0;
  }
}

.update-circular-loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-zipcodes-btn {
  margin-top: 24px;
  height: 48px;
  min-width: 120px;
}

.edit-pop-up {
  ul {
    li {
      span {
        font-size: 14px;
      }
    }
  }

  .MuiMenu-paper {
    .MuiMenu-list {
      outline: 0;
      padding: 0 !important;
      display: inline-block;
      width: 100% !important;
    }
  }
}

.span-copied-successfully {
  position: absolute;
  top: -20px;
  z-index: 2;
  background: map-get($i-colors, "color-white");
  padding: 4px 13px;
  left: -28px;
  border-radius: 5px;
  box-shadow: $boxShadow-cutom !important;
  -moz-box-shadow: $boxShadow-cutom !important;
  -webkit-box-shadow: $boxShadow-cutom !important;

  svg {
    color: map-get($i-colors, "success-text") !important;
    font-size: 16px;
    margin-right: 5px;
  }
}

.astrick-sign {
  color: map-get($i-colors, "text-error");
}

.date-range-picker {
  .CalendarDay__selected_span {
    background: map-get($i-colors, "color-theme-21");
    color: map-get($i-colors, "color-white"); //text
    border: 1px solid map-get($i-colors, "color-white");
  }

  .CalendarDay__selected {
    background: map-get($i-colors, "color-theme-21");
    color: map-get($i-colors, "color-white");
    border: 1px solid map-get($i-colors, "color-white");
  }

  .CalendarDay__selected:hover {
    background: map-get($i-colors, "primary");
    color: map-get($i-colors, "color-white");
    border: 1px solid map-get($i-colors, "color-white");
  }

  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: map-get($i-colors, "color-theme-21");
    color: map-get($i-colors, "color-white");
    border: 1px solid map-get($i-colors, "color-white");
  }

  .start-end-date-divider {
    font-size: 25px;
    color: map-get($i-colors, "color-theme-23");
  }

  .DateRangePickerInput__withBorder {
    border-radius: 5px;
    border: 1px solid map-get($i-colors, "color-theme-6");
    height: 48px;
    min-width: 330px;
    display: flex;
    align-items: center;
  }

  .date-picker-icon {
    color: map-get($i-colors, "color-theme-9");
  }
}

.importing-partners {
  display: flex;
  justify-content: space-between;
  padding: 10px 8px;
  align-items: center;

  span {
    word-break: normal;
  }
}

.blinking-svg {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {

  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.download-csv {
  svg {
    border-radius: 50%;
    border: 1px solid;
    width: 40px;
    height: 40px;
    padding: 7px;
    color: map-get($i-colors, "secondary");

    &:hover {
      color: map-get($i-colors, "primary");
    }
  }
}

// .divided-chart-list {
// 	max-height: 350px;
// 	overflow: auto;
// }

.internal-tabs>.MuiBox-root {
  padding: 10px;
}

.dashboard-mui-table-custom {
  height: 600px;
  overflow: auto;
  margin-bottom: 20px;
}

.move-chart-to-center {
  display: flex;

  div {
    margin: auto;
  }
}

.custom-table-height {
  min-height: calc(100vh - 240px);
  height: auto;
}

.clear-address-icon {
  color: map-get($i-colors, "color-grey");
  cursor: pointer;
}

.main-advanced-loader {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  background: map-get($i-colors, "color-theme-12");
  overflow: hidden;
  display: flex;

  div {
    margin: auto !important;
  }
}

.revenue-card-box {
  margin: 20px 14px !important;
}

.autoselect-date-filters {
  padding-right: 14px;
}

.formgroup-help-icon {
  font-size: 18px !important;
  margin-left: 5px !important;
  margin-bottom: 2px !important;
}

.meal-type-dot {
  margin-left: 0px;
  margin-right: 8px;
  top: 2px;
  height: 16px;
  width: 16px;
  position: relative;
  display: inline-block;
  border: 2px solid map-get($i-colors, "color-theme-13");

  span.inner {
    position: absolute;
    background: map-get($i-colors, "color-theme-13");
    height: 8px;
    width: 8px;
    border-radius: 8px;
    top: 2px;
    left: 2px;
  }

  &.green {
    border: 2px solid map-get($i-colors, "color-theme-14");

    span.inner {
      background: map-get($i-colors, "color-theme-14");
    }
  }
}

.bb-nameplate-tooltip {
  p {
    font-size: 14px;
    padding: 4px 4px;

    span {
      font-weight: bold;
      margin-right: 8px;
    }
  }
}

.bb-link {
  cursor: pointer;
  font-weight: bold;
}

/* Weekly Menu Css Start */
.weekly-menu-sec {
  .weekly-menu-container {
    .weekly-menu-listing {
      width: 100%;
      display: inline-block;
      background: map-get($i-colors, "color-white");
      border: 1px solid map-get($i-colors, "color-theme-15");
      -moz-box-shadow: $boxShadow-cutom !important;
      -webkit-box-shadow: $boxShadow-cutom !important;
      box-shadow: $boxShadow-cutom !important;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 25px;

      .table-program {
        tbody {
          td {
            padding-top: 10px;
            padding-bottom: 10px;
            // white-space: initial;
            text-transform: capitalize;
          }
        }
      }

      .menu-heading {
        background: map-get($i-colors, "primary");
        width: 100%;
        display: inline-block;
        padding: 15px 10px;

        h2 {
          font-size: 16px;
          font-weight: 600 !important;
          text-align: center;
          padding: 0;
          color: map-get($i-colors, "color-white");
        }
      }

      .weekly-inner-con {
        width: 100%;
        display: flex;
        padding: 20px 30px;
        min-height: 150px;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;

        .menu-item {
          display: flex;
          align-items: center;

          p {
            width: calc(100% - 24px);
          }
        }

        p {
          width: 100%;
          display: flex;
          align-items: center;
          padding: 6px 0;

          span {
            margin-left: 8px;
            margin-right: 8px;
            height: 16px;
            width: 16px;
            position: relative;
            display: inline-block;

            span {
              position: absolute;
              background: map-get($i-colors, "color-theme-14");
              height: 8px;
              width: 8px;
              border-radius: 8px;
              top: 2px;
              left: 2px;
              margin: 0;
            }

            &.veg-meal {
              border: 2px solid map-get($i-colors, "color-theme-14");

              span {
                background: map-get($i-colors, "color-theme-14");
              }
            }

            &.non-veg-meal {
              border: 2px solid map-get($i-colors, "color-theme-13");

              span {
                background: map-get($i-colors, "color-theme-13");
              }
            }
          }
        }
      }
    }
  }
}

/* Weekly Menu Css Ends */

.dashboard-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .MuiCircularProgress-root.MuiCircularProgress-indeterminate {
    color: map-get($i-colors, "color-black") !important;
  }
}

.main-advanced-loader {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 999;
  background: map-get($i-colors, "color-theme-16");
  overflow: hidden;
  display: flex;

  div {
    margin: auto !important;
  }
}

.download-img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.dashboard-card-img {
  background: map-get($i-colors, "color-theme-17");
  border-radius: 10px;
  height: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;

  img {
    height: 30px;
    width: auto;
  }
}

.position-relative {
  position: relative;

  .wallet-amount-text {
    position: absolute;
    top: -20px;
    right: 0;
    display: flex;
  }
}

.dashboard-card-connent {
  h5 {
    color: map-get($i-colors, "primary");
  }

  h6 {
    color: map-get($i-colors, "secondary");
    margin-top: 3px;
  }

  p {
    color: map-get($i-colors, "secondary");
    font-size: 14px;
    font-weight: normal;
    margin-top: 3px;
  }

  .meal-type-dot {
    margin-left: 8px;
  }
}

.invoice-success-icon {
  height: 20px !important;
  width: 20px !important;
}

.progress {
  background-color: map-get($i-colors, "color-white");
}

.cus-loader-design {
  &.progress {
    height: 20px;
    width: 20px;
    font-size: 1rem;
    background-color: transparent;
    border-radius: 0;
    margin-top: 0;

    >.MuiCircularProgress-root {
      width: 20px !important;
      height: 20px !important;
    }

    svg {
      height: 20px !important;
    }
  }
}

.cus-tabs-design {
  width: 100%;
  background-color: map-get($i-colors, "color-white");
  -moz-box-shadow: $boxShadow !important;
  -webkit-box-shadow: $boxShadow !important;
  box-shadow: $boxShadow !important;
  border-radius: 5px;
  margin-top: 16px;

  .MuiTabs-flexContainer {
    padding-left: 0;
  }

  .MuiTabs-indicator {
    height: 2px;
    border-radius: 0;
  }

  button {
    text-transform: capitalize;
    color: map-get($i-colors, "color-black");
    font-weight: 800;
    min-width: 200px;

    &.tab-approvedmeals,
    &.tab-newinvoice {
      color: map-get($i-colors, "color-green-badges");

      .total-meal-data {
        label {
          background-color: map-get($i-colors, "color-green-badges");
        }
      }
    }

    &.tab-pendingapproved,
    &.tab-paidinvoice {
      color: map-get($i-colors, "color-yellow-badges");

      .total-meal-data {
        label {
          background-color: map-get($i-colors, "color-yellow-badges");
        }
      }
    }

    &.tab-declined,
    &.tab-holdinoice {
      color: map-get($i-colors, "color-red-badges");

      .total-meal-data {
        label {
          background-color: map-get($i-colors, "color-red-badges");
        }
      }
    }

    &.tab-processedInvoice {
      color: map-get($i-colors, "color-blue");

      .total-meal-data {
        label {
          background-color: map-get($i-colors, "color-blue");
        }
      }
    }

    .total-meal-data {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      label {
        margin: 0 0 0 12px;
        background-color: map-get($i-colors, "color-black");
        min-width: 34px;
        border-radius: 14px;
        color: map-get($i-colors, "color-white");
        font-size: 12px;
        padding: 0px 6px;
      }
    }
  }
}

.meals-field {
  a {
    color: map-get($i-colors, "color-yellow");
    cursor: pointer;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: map-get($i-colors, "color-yellow");
      text-decoration: underline !important;
    }
  }
}

.tuck-shop-order-item-field {
  a {
    color: map-get($i-colors, "color-yellow");
    cursor: pointer;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: map-get($i-colors, "color-yellow");
      text-decoration: underline !important;
    }
  }
}

.td-action-btns {
  width: 160px;
  display: inline-block;

  ul {
    width: auto;
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 8px 0 0;
      display: inline-block;
      width: auto;
      cursor: pointer;
    }
  }

  .btns-group-sec {
    margin: 0 8px 8px 0;
    display: inline-block;
    width: auto;

    &.sec-approved {
      .daily-supply-listing {
        color: map-get($i-colors, "color-green-badges");

        svg {
          color: map-get($i-colors, "color-green-badges");
          margin-right: 4px;
        }
      }
    }

    &.sec-declined {
      .daily-supply-listing {
        color: map-get($i-colors, "color-red-badges");

        svg {
          color: map-get($i-colors, "color-red-badges");
          margin-right: 4px;
        }
      }
    }

    .daily-supply-listing {
      width: 100%;
      display: flex;
      align-items: center;
      color: map-get($i-colors, "color-black");
      cursor: pointer;

      svg {
        color: map-get($i-colors, "color-black");
        margin-right: 4px;
      }
    }
  }
}

.view-note-sec {
  a {
    cursor: pointer;
    color: map-get($i-colors, "secondary");
    width: 100%;
    display: flex;
    align-items: center;

    svg {
      margin-right: 4px;
    }

    &:hover {
      color: map-get($i-colors, "secondary");
    }
  }
}

.comments-here {
  p {
    font-size: 16px;
  }
}

.table-program {
  .daily-supply-listing-status {
    width: auto;
    background-color: map-get($i-colors, "color-yellow-badges");
    border: 1px solid map-get($i-colors, "color-yellow-badges");
    color: map-get($i-colors, "color-white");
    border-radius: 6px;
    display: inline-block;
    padding: 6px 10px;
    font-size: 12px;
    min-width: 100px;
    text-align: center;
    font-weight: 700;

    &.declined {
      background-color: map-get($i-colors, "color-red-badges");
      border: 1px solid map-get($i-colors, "color-red-badges");
    }

    &.approved,
    &.invoice-status-new {
      background-color: map-get($i-colors, "color-green-badges");
      border: 1px solid map-get($i-colors, "color-green-badges");
    }

    &.invoice-status-processed {
      background-color: map-get($i-colors, "color-blue-badges");
      border: 1px solid map-get($i-colors, "color-blue-badges");
    }

    &.invoice-status-hold2 {
      background-color: map-get($i-colors, "color-red-badges");
    }
  }
}

.foot-modal-btns {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 0px;
  padding-bottom: 20px;
  // border-bottom: 1px solid rgba(224, 224, 224, 1);

  button {
    margin: 5px 10px;
    min-width: 150px;
    box-shadow: none;
    font-weight: 600;
    padding: 8px 16px;
    border-radius: 30px;

    &:hover {
      box-shadow: none;
    }
  }
}

body {
  .MuiDialog-root {
    .MuiDialogTitle-root {
      background-color: map-get($i-colors, "primary");
      padding: 16px 24px;

      h6,
      button {
        color: map-get($i-colors, "text-main");
      }
    }

    .MuiDialogContent-dividers {
      padding: 16px 24px;
      overflow: visible;
    }

    .MuiDialogActions-root {
      padding: 8px 24px;
    }

    main {
      .table-design {
        margin-top: 0;
      }
    }

    .modal-comments-sec {
      width: 100%;
      display: inline-block;
      padding: 16px 24px;

      h5 {
        font-size: 16px;
        padding-bottom: 8px;
      }

      .table-design {
        max-height: 250px;
        overflow: auto;

        table {
          tr {

            td,
            th {
              padding: 10px;

              &:first-child,
              &:last-child {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

.ver-tabs-design {
  width: 100%;
  display: flex;
  flex-grow: 1;
  margin-top: 20px;
  border-radius: 5px;
  overflow: hidden;
  -moz-box-shadow: $boxShadow !important;
  -webkit-box-shadow: $boxShadow !important;
  box-shadow: $boxShadow !important;
  background-color: map-get($i-colors, "color-white");

  .ver-tabs-left {
    display: flex;
    width: 200px;
    border-right: 1px solid map-get($i-colors, "color-theme-29");
  }

  .ver-tabs-right {
    width: calc(100% - 200px);
  }
}

main {
  .ver-tabs-design {
    .table-design {
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      border-radius: 0;
      padding: 0;
      margin-top: 0;
    }

    .ver-tabs-left {
      .MuiTabs-flexContainer {
        padding: 0;

        button {
          border-bottom: 1px solid map-get($i-colors, "color-theme-29");
          height: 55px;
          padding: 6px 5px 6px 15px;

          span {
            text-align: left;
            display: inline-block;
            width: 100%;
            color: map-get($i-colors, "color-theme-7");
            text-transform: capitalize;
          }

          &.Mui-selected {
            span {
              color: map-get($i-colors, "color-black");
              font-weight: 900;
            }
          }
        }
      }
    }
  }
}

.emp-change-select {
  >div {
    width: 240px;
    min-height: 48px;
    box-shadow: none;
    z-index: 3;

    &:hover,
    &:focus {
      border-color: map-get($i-colors, "secondary");
    }
  }
}

.meals-details-modal {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    width: 100%;
    display: inline-block;
    font-size: 16px;
  }

  .table-program {

    th,
    td {
      min-width: 120px;
    }
  }

  .meal-modal-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 12px;

    h5 {
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}


.items-details-modal {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    width: 100%;
    display: inline-block;
    font-size: 16px;
  }

  .table-program {

    th,
    td {
      min-width: 120px;
    }
  }

  .items-modal-head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 5px 0 12px;

    h5 {
      &:last-child {
        align-items: center;
        justify-content: flex-end;
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }
  }
}




/* Working Days css Start */
.cus-days-inn {
  width: 100%;
  display: inline-block;
  text-align: left;
  margin-top: 30px;

  h5 {
    padding-bottom: 12px !important;
  }

  h6 {
    padding-bottom: 10px;
  }

  ul {
    width: 100%;
    display: grid;
    align-items: center;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0 0 10px 0;
    grid-template-columns: auto auto auto;

    li {
      label {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }

  .MuiAccordion-root {
    box-shadow: $boxShadow !important;
    background-color: map-get($i-colors, "color-white");

    .MuiAccordionSummary-content {
      margin: 20px 0;

      h6 {
        padding: 0;
      }
    }
  }
}

/* Working Days css Ends */
.bal-custom {
  margin-right: 5px;
}

.employee_btn_section .custom-search-field {
  margin-right: 0;
}

.modal-textarea-modal {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .plus-btn {
    label {
      display: block;
      width: 100%;
      font-weight: 800;
      margin-bottom: 8px;

      .veg-theme {
        color: #52d869;
      }

      .non-veg-theme {
        color: $red-color;
      }
    }
  }

  .btn-modal-textarea {
    button {
      background: #9e9e9e33;
      padding: 0;
      min-width: 40px;
      font-size: 24px;
      line-height: 32px;
      height: 34px;
    }

    .count {
      min-width: 40px;
      display: inline-block;
      text-align: center;
      font-size: 16px;
      height: 36px;
    }
  }
}

.tras-bin>div {
  margin-left: 20px;
  margin-right: 0 !important;

  .tras-bin-btn {
    height: 42px;
    min-width: auto;
    color: #fff;

    &.add-employee {
      background-color: $active-color ;
    }
  }

}

.grid-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 20px;
}

.orange_badge,
.green_badge,
.red_badge {
  font-weight: 600 !important;
  padding: 2px 10px 4px;
  border-radius: 22px;
  color: #fff;
  font-size: 13px;
}

.orange_badge {
  background: $yellow-color;
}

.green_badge {
  background: $active-color;
}

.red_badge {
  background: $red-color;
}

.red_text {
  color: $red-color;
}

.button-btn-csv {
  height: 42px;
  min-width: 42px !important;
  background-color: #4c6072 !important;
  padding: 0 !important;
  color: #fff !important;
}

.add-employee {
  background-color: #2e9e32 !important;
}

.wallet-amount-btn {
  background-color: #93572b !important
}

.demo-export {
  align-items: center;
  justify-content: space-between;
  border: 1px solid #4c607245;
  padding: 4px 10px;
  margin-bottom: 20px;
  font-weight: 600;

  button {
    min-width: 40px;
    max-width: 42px;
  }
}

.select-file-csv>span {
  min-width: 42px;
  max-width: 42px;
  color: #fff !important;
  background-color: #4c6072;
}

.both_meals {
  .meal-type-dot {
    margin-left: 8px;
  }
}

.note_style {
  font-style: italic;
  font-size: 12px;
}

.tras-bin-btn {
  position: relative;

  &:hover {
    &::after {
      position: absolute;
      content: attr(data-tooltip);
      bottom: -3em;
      right: -1.5em;
      background-color: #333;
      color: #fff;
      padding: 0.5em 1em;
      font-size: 10px;
      white-space: nowrap;
      border-radius: 4px;
      font-weight: 800;
    }
  }
}

a.view-list-emp {
  display: flex;
  text-decoration: none;
  color: #000;

  button {
    color: #3298d9 !important;

    svg {
      margin-left: 10px;
    }
  }
}
.note_style{
  display:flex;
  gap:10px;
  align-items: center;
    justify-content: center;
  .refresh-icon{
    float:right;
    text-align: center;
    cursor: pointer;
}
.second_note{
  margin-left: 32px;
}
}
