$colors: (
  offwhite: #f2f2f2,
  white: #ffffff,
  grey: #5f5d5d,
  dark-grey: #222222,
  grey-shade: #e8e8e8,
  forget-color: #616161,
  term-color: #7d7d7d,
  grey-color: #dde3ef,
  select-color: #ebebeb,
  light-border: #d6d6d6,
  active-color: #2e9e32,
  yellow-color: #f9c546,
);

$boxShadow: 0px 2px 6px 0px #e0e0e0;
$border-radius: 16px;
$boxShadow-cutom: 0px 0px 8px #00000026;

$active-color: #2e9e32;
$yellow-color: #f9c546;
$red-color: #bb2d2d;