$i-colors: (
  "primary": #f9c546,
  "secondary": #4c6072,
  "text-main": #333333,
  "border-main": #4c607233,
  "bg-light": #f6f8fa,
  "text-error": #ff0000,
  "success-text": #008000,
  "color-white": #ffffff,
  "color-black": #000000,
  "color-green": #3aa41f,
  "color-red": #e2361d,
  "color-grey": #e0e0e0,
  "color-yellow": #1c2b79,
  "dark-grey": #222222,
  "grey-shade": #e8e8e8,

  "color-red-badges": #d12a11,
  "color-blue-badges": #1c2b79,
  "color-yellow-badges": #f8bc24,
  "color-green-badges": #38692c,

  "color-grey-1": #cacccd,
  "color-grey-2": #f7f5f5fa,
  "color-grey-3": #e7eaed,
  "color-grey-4": #ebebeb,
  "color-grey-5": #f8d1b3,
  "color-grey-6": #d89d1d,
  "color-theme-1": #fbd074,
  "color-theme-2": #c4c4c4,
  "color-theme-3": #d4d3d3,
  "color-theme-4": #ee2b53,
  "color-theme-5": #eeeeee,
  "color-theme-6": #dedede,
  "color-theme-7": #666666,
  "color-theme-8": #bbbbbb,
  "color-theme-9": #a5a5a5,
  "color-theme-10": #a3a3a7,
  "color-theme-11": #e3e5e6,
  "color-theme-12": #3a3a3a75,
  "color-theme-13": #bb2d2d,
  "color-theme-14": #52d869,
  "color-theme-15": #dadada,
  "color-theme-16": #33323275,
  "color-theme-17": #FFFAEF,
  "color-theme-21": #d05063bd,
  "color-theme-22": rgba(0, 0, 0, 0.04),
  "color-theme-23": rgb(192, 192, 192),
  "color-theme-24": rgb(236, 236, 236),
  "color-theme-25": rgb(221, 221, 221),
  "color-theme-26": rgba(0, 0, 0, 0.23),
  "color-theme-27": #cccccc,
  "color-theme-28": #c29776,
  "color-theme-29": #ECECEC,
  "color-theme-30": #767676,
  "color-theme-31": #F9F9F9,
  "color-theme-33": #8096AA,
);

:export {
  primary: map-get($i-colors, "primary");
  secondary: map-get($i-colors, "secondary");
  textMain: map-get($i-colors, "text-main");
  borderMain: map-get($i-colors, "border-main");
  bgLight: map-get($i-colors, "bg-light");
  textError: map-get($i-colors, "text-error");
  successText: map-get($i-colors, "success-text");
  colorWhite: map-get($i-colors, "color-white");
  colorWhite: map-get($i-colors, "color-black");
}
